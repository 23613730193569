/* You can add global styles to this file, and also import other style files */
.example-button-row {
  display: table-cell;
  width: 490px;
}

.example-button-row .mat-button-base {
  margin: 8px 8px 8px 0;
}

.preloader.active {
  visibility: visible;
  opacity: 0.3 !important;
}

.preloader {
  z-index: 99999 !important;
  background-color: #fff !important;
  /* position: absolute; */
}

.toast-error {
  background-image: none !important;
  background-color: #dc3545 !important;
  width: 100% !important;
  box-shadow: 0px 2px 20px rgb(0 0 0 / 20%) !important;
}

.toast-success {
  background-image: none !important;
  background-color: #08871c !important;
  width: 100% !important;
  box-shadow: 0px 2px 20px rgb(0 0 0 / 20%) !important;
}

.toast-info {
  background-image: none !important;
  background: #0037ac !important;
  width: 100% !important;
  box-shadow: 0px 2px 20px rgb(0 0 0 / 20%) !important;
}

.komunikat_error {
  height: 24px;
  padding-left: 40px;
  background: url("assets/img/error.svg") no-repeat left center !important;
}

.komunikat_success {
  height: 24px;
  padding-left: 40px;
  background: url("assets/img/success.svg") no-repeat left center !important;
}

.toast-container .ngx-toastr {
  padding: 15px 15px 15px 15px !important;
  min-height: 64px;
}

.info {
  padding: 15px 15px;
}

.info.success {
  background: #2a7d3d;
}

.info.success .text {
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.info.success .text a {
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-weight: 700;
  text-decoration: underline;
}

.info.success .text.ico01 {
  padding-left: 25px;
  background: url("data:image/svg+xml,%3Csvg fill='none' height='13' viewbox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg'%3E %3Crect fill='%232A7D3D' height='9.75' rx='2' width='9.75' x='1.625' y='1.625'%3E %3C/rect%3E %3Cpath d='M9.74463 4.87464L8.98088 4.10547L5.4113 7.67505L4.0138 6.28297L3.24463 7.04672L5.4113 9.20797L9.74463 4.87464Z' fill='white'%3E %3C/path%3E %3Cpath d='M0 0V-1H-1V0H0ZM13 0H14V-1H13V0ZM13 13V14H14V13H13ZM0 13H-1V14H0V13ZM0 1H13V-1H0V1ZM12 0V13H14V0H12ZM13 12H0V14H13V12ZM1 13V0H-1V13H1Z' fill='white'%3E %3C/path%3E %3C/svg%3E")
    no-repeat left 1px;
}

.error_info {
  padding: 15px 10px 0;
}

.info__text {
  padding-top: 0px;
  padding-left: 40px;
  background: url("assets/img/info.svg") no-repeat left center;
}

.modal-content .close {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23a8a8a5' d='M11.832 10.01l7.788-7.788A1.294 1.294 0 1 0 17.788.39L10 8.178 2.212.39A1.294 1.294 0 1 0 .38 2.222l7.788 7.788L.38 17.798a1.294 1.294 0 0 0 .916 2.212c.332 0 .664-.127.916-.38L10 11.842l7.788 7.788a1.292 1.292 0 0 0 1.832 0 1.294 1.294 0 0 0 0-1.832z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
    no-repeat center center;
  opacity: initial !important;
}

/* .dropdown-menu { 
     top: initial !important;
    } */

/* .dropdown-menu-right { 
     right:35px !important ;
    } */

.paginate__select {
  width: 50px;
}

@media screen and (max-width: 420px) {
  .days li .active {
    padding: 0px;
  }
}

.w-33 {
  width: 33.3% !important;
}

.ng-select .ng-select-container:after {
  border-bottom: 0px solid #7d7d7d !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  padding: 0px 10px 8px;
}

@media screen and (max-width: 768px) {
  section.users .user .edit_client {
    top: -110px;
  }
}

.users .ng-select .ng-select-container:after {
  border-bottom: 0px solid #7d7d7d !important;
}

.ng-input input[type="text"] {
  min-height: initial;
}

.ng-select-container {
  margin: 0;
  width: 100%;
  font-size: 16px !important;
  background-color: #fafafa !important;
  appearance: none;
  color: #000000;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.14px;
  line-height: normal;
}

.ng-select-error .ng-select-container {
  border: 1px solid red;
}

.ng-select-container.ng-has-value {
  padding-top: 15px;
  padding-bottom: 5px;
  padding-left: 12px;
}

.ng-select-opened .ng-select-container {
  padding-top: 15px;
  padding-left: 12px;
}

.ng-select-focused .ng-select-container {
  padding-top: 15px;
  padding-left: 12px;
}

.ng-select .ng-select-container .ng-value-container {
  padding: 0em 0 !important;
}

.ng-select .ng-select-container {
  /* min-height: 47px !important; */
  min-height: initial !important;
  /* height: 47px !important; */
  border-radius: 8px;
  padding-left: 5px;
  /* background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M6.99999 5.17217L11.95 0.222168L13.364 1.63617L6.99999 8.00017L0.635986 1.63617L2.04999 0.222168L6.99999 5.17217Z' fill='%2300A7E8'/%3E %3C/svg%3E") 96% center no-repeat #f4f4f4; */
  background: url("data:image/svg+xml,<svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M7.12061 5.17266L12.0545 0.222656L13.4639 1.63666L7.12061 8.00066L0.777344 1.63666L2.18674 0.222656L7.12061 5.17266Z' fill='%230037AC'/> </svg>")
    96% center no-repeat #f4f4f4;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #848486 !important;
}

.ng-select .ng-arrow-wrapper {
  display: none;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  color: #848486 !important;
}

.ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  color: #848486 !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: inherit !important;
  color: inherit !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  background-color: inherit !important;
  color: inherit !important;
}

.ng-value-label {
  white-space: normal;
}

.ng-option-label {
  white-space: normal;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: normal !important;
  min-height: initial !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

/* .ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder{
  color:   rgba(0,0,0,0.54) !important;
} */
.ng-select {
  padding-bottom: 0px !important;
}

.ng-select-container .error {
  font-size: 16px !important;
}

.ng-select-error {
  color: #ff7058;
  font-size: 16px;
}

.modal-content {
  border: none !important;
}

.preloader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #3c9798;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;
}

.preloader__content {
  height: 150px;
  width: 100%;
  position: relative;
}

.preloader__icon {
  position: absolute;
  width: 94px;
  height: 94px;
  left: 50%;
  top: 50%;
  margin-left: -47px;
  margin-top: -47px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.preloader__mask {
  position: absolute;
  width: 148px;
  height: 148px;
  margin-left: -74px;
  margin-top: -74px;
  left: 50%;
  top: 50%;
}

.preloader.active {
  visibility: visible;
  opacity: 1;
}

.btn__input--eye.active {
  border: none;
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0;
  right: 0;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z' fill='%23848486'/%3E %3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.2928 21.293C1.90228 20.9025 1.90228 20.2694 2.2928 19.8788L20.8786 1.29304C21.2691 0.902521 21.9023 0.90252 22.2928 1.29304C22.6833 1.68357 22.6833 2.31673 22.2928 2.70726L3.70701 21.293C3.31649 21.6836 2.68332 21.6836 2.2928 21.293Z' fill='%23848486'/%3E %3C/svg%3E")
    no-repeat center center;
}

.fs__login--info.error {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.857143px;
  color: #ad1d2b !important;
}

.ico__logout {
  cursor: pointer;
}

.loader {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.tableFixHead thead th {
  z-index: 999 !important;
}

.btn__deactivate {
  border: none;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border-radius: 10px;
  border: 1.5px solid #ad1d2b;
  color: #ad1d2b;
  font-weight: bold;
  letter-spacing: 1.42857px;
}

.btn__send_link {
  border: none;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border-radius: 10px;
  border: 1.5px solid #08871c;
  color: #08871c;
  font-weight: bold;
  letter-spacing: 1.42857px;
}

.btn__activate {
  border: none;
  width: 100%;
  height: 44px;
  background: #5f5f61;
  border-radius: 10px;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 1.42857px;
}

.btn__input--sort--bottom--gray {
  top: initial !important;
  width: 24px;
  height: 24px;
  background: url("assets/img/arrow-up-down.png") no-repeat right center !important;
}

.btn__input--sort--bottom--gray.down {
  top: initial !important;
  width: 24px;
  height: 24px;
  background: url("assets/img/arrow-down.png") no-repeat right center !important;
}

.btn__input--sort--bottom--gray.up {
  top: initial !important;
  width: 24px;
  height: 24px;
  background: url("assets/img/arrow-up.png") no-repeat right center !important;
}

.btn__input--sort--bottom.up {
  transform: rotate(180deg);
}

.pointer {
  cursor: pointer;
}

.project-row:hover {
  background-color: #e9eef4;
}

.navbar .person_name {
  width: auto;
}

.btn__slownik {
  padding-left: 34px;
}

/* .ng-select-container .ng-value{
  display: none;
} */

.ng-select-single {
  background: #fafafa;
  border: 1px solid #bfbfc1;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  position: initial !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.ng-dropdown-panel.ng-select-top {
  margin-bottom: 20px !important;
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 20px;
}

.ng-select-searchable.ng-select.ng-select-single:not(.ng-select-opened) {
  height: 47px !important;
}

.ng-select-searchable.ng-select.ng-select-single.ng-select-opened {
  min-height: 51px !important;
}

.ng-select-searchable.ng-select.ng-select-single.ng-select-opened
  .ng-select-container {
  min-height: 51px !important;
}

.ng-select-searchable.ng-select.ng-select-single:not(.ng-select-opened)
  .ng-select-container {
  height: 47px !important;
}

.ng-dropdown-panel {
  background: #fafafa !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btn__select__add {
  word-wrap: break-word !important;
  white-space: normal !important;
}

@media (min-width: 920px) {
  .md-white-box {
    margin-bottom: 60px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input_container--mini button.btn__input--search {
  margin-right: 5px !important;
}

.input_container--mini button.btn__input--clear {
  margin-right: 5px !important;
}

.box__mobile--table
  .fixed_table_container
  table
  th
  .btn__input--sort--bottom--gray {
  width: 25px;
  height: 25px;
}

.table tr td {
  padding: 10px 5px !important;
}

.cdk-overlay-container {
  z-index: 100000 !important;
}

.mat-form-field {
  background: #fafafa;
  border: 1px solid #bfbfc1;
  border-radius: 8px;
  height: 44px;
  width: 100%;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  color: rgba(95, 95, 97, 0.8) !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 0.75em 0 0.75em !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: initial !important;
}

.mat-form-field-underline {
  display: none;
}

.error .mat-form-field-appearance-fill .mat-form-field-flex {
  border: 1px solid #b54747 !important;
  border-radius: 8px;
}

.autocomplete-container .input-container input {
  padding: 9px 15px 8px !important;
  background: #fafafa !important;
  border: 1px solid #bfbfc1 !important;
  border-radius: 8px !important;
  height: 44px !important;
  width: 100% !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  transition: border 500ms ease-out !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 1.14286px !important;
  color: rgba(95, 95, 97, 0.8) !important;
}

.ng-autocomplete {
  width: 100% !important;
}

.btn__edit--min {
  top: initial;
  bottom: initial;
}

.btn__edit {
  height: 25px;
  width: 25px;
}

.btn__badge:hover,
.btn__badge.active {
  background-color: #0037ac;
  color: white;
}

.table tr td {
  padding: 0px 5px !important;
}

.container--mw800 {
  max-width: 800px;
  margin: 0 auto;
}

div.full-screen-width-modal {
  max-width: 95% !important;
}

.btn__dolar {
  border: none;
  width: 25px;
  height: 25px;
  background-image: url(assets/img/dollar.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-color: transparent;
}
